.leaflet-container {
    width: 100% !important;
    height: 100vh;
  }

.legend {
  display: flex;
  position: fixed; /* Stay in place */
  left: 0;
  bottom: 0;
  z-index: 999;
  height: 25px;
  padding-bottom: 15px;
  padding-left: 10px;

  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
	font-size: 0.85rem;

  &__flag {
    width: 25px;
    height: 25px;
    vertical-align: sub;
  }
}

.popUpImage {
  max-Width: 150px;
  max-height: 120px;
  border: solid 5px white;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: 0.3s;
}

.popUpImage:hover {
  opacity: 0.7;
}

.popUpText {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
}

@keyframes animatedBackground {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* The Modal (background) */
.fullScreenModal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: black;
  z-index: 99997;
  background-position: center center; 
  animation: animatedBackground 3s ease-in-out;

  &__caption {
    background-image: -moz-linear-gradient(bottom, rgba(16,16,16,0.75), rgba(16,16,16,0.25) 80%, rgba(16,16,16,0));
    background-image: -webkit-linear-gradient(bottom, rgba(16,16,16,0.75), rgba(16,16,16,0.25) 80%, rgba(16,16,16,0));
    background-image: -ms-linear-gradient(bottom, rgba(16,16,16,0.75), rgba(16,16,16,0.25) 80%, rgba(16,16,16,0));
    background-image: linear-gradient(bottom, rgba(16,16,16,0.75), rgba(16,16,16,0.25) 80%, rgba(16,16,16,0));
    padding: 2em 2em 0.75em 2em ;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: white;
    z-index: inherit;
    display: none;
    font-size: xx-large;
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    display: none;
    cursor: pointer;
  }

  &__nav-next,
	&__nav-previous {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: absolute;
    top: 55%;
    margin-top: -3em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  &__nav-previous {
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1);
    left: 0;
  }

  &__nav-next {
    right: 0;
  }

  &__close:hover,
  &__close:focus,
  &__nav-next:hover,
	&__nav-previous:hover,
  &__nav-next:focus,
	&__nav-previous:focus {
    opacity: 0.7;
  }
}

.fullScreen {
  display: block !important;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)} 
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)} 
  to {transform:scale(1)}
}


/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }

  .fullScreenModal {
    &__caption {
      font-size: small !important;
    }
  }

}